<template>
  <div class="upload">
    <p class="theme-color"> 证件照片</p>
    <div class="image-assets">
      <input
        type="file"
        accept="image/*"
        class="image"
        id="idPhoto1"
        style="visibility: hidden"
        @change="selectFileImage($event, 'idPhoto1')"
      />
      <label for="idPhoto1">
        <van-image class="showimage-assets" :src="idPhoto1 || defaultPic"></van-image>
      </label>
    </div>
    <div class="image-assets">
      <input
        type="file"
        accept="image/*"
        class="image"
        id="idPhoto2"
        style="visibility: hidden"
        @change="selectFileImage($event, 'idPhoto2')"
      />
      <label for="idPhoto2">
        <van-image class="showimage-assets" :src="idPhoto2 || defaultPic"></van-image>
      </label>
    </div>
    <div class="upload-tip theme-color">点击图片上传或更新</div>
    <div class="common-assets-tip theme-color">
      <span>提示：</span><br />
      <span>1.拍摄时请确保证件平放，光线充足。</span><br />
      <span>2.确保文字清晰、边框完整。</span><br />
      <span>3.个人客户可上传身份证等证件，企业客户可上传营业执照和空白纸上盖的公章照片。</span> <br />
    </div>
  </div>
</template>

<script>
import {
  Uploader, Image as VanImage, ImagePreview,
} from 'vant';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import uploadImage from './uploadImage';
import httpApi from '../utils/httpApi';

const defaultImage = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/3_live.png';
export default {
  name: 'Upload',
  components: {
    [Uploader.name]: Uploader,
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      defaultPic: defaultImage,
    };
  },
  methods: {
    showPreview(key) {
      ImagePreview([key]);
    },
    async selectFileImage(event, key) {
      const file = event.target.files[0];
      uploadImage(file, key, (photo) => {
        this[key] = photo;
      });
    },
  },
  setup() {
    const idPhoto1 = ref('');
    const idPhoto2 = ref('');
    const store = useStore();
    onMounted(async () => {
      store.commit('setTitleValue', '上传证件照片');
      store.commit('setStepsShow', false);
      const resPhoto1 = await httpApi.getImage({ type: 'idPhoto1' });
      const resPhoto2 = await httpApi.getImage({ type: 'idPhoto2' });
      if (resPhoto1 && resPhoto1.code === 200) {
        idPhoto1.value = resPhoto1.data.value;
      }
      if (resPhoto2 && resPhoto2.code === 200) {
        idPhoto2.value = resPhoto2.data.value;
      }
    });
    return {
      idPhoto1,
      idPhoto2,
    };
  },
};
</script>

<style lang="less">
  .image-assets {
    width: 300px;
    height: 200px;
    margin: 10px auto;
    position: relative;
  }
  .showimage-assets {
    position: absolute;
    width: 300px;
    height: 200px;
    top: 0;
    left: 0;
  }
  .pdf-name {
    margin: auto;
    text-align: center !important;
    margin: 20px;
  }
  .upload-tip {
    text-align: center !important;
    color: @font-color-2;
    margin-top: @space-2;
  }
  .common-assets-tip {
    margin-top: 10px;
    color: @font-color-1;
    padding-left: 0px;
    padding-right: 0px;
  }
</style>
