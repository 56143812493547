import { Toast, Dialog } from 'vant';
import EXIF from 'exif-js';
import httpApi from '../utils/httpApi';

const testAutoOrientationImageURL = 'data:image/jpeg;base64,/9j/4QAiRXhpZgAATU0AKgAAAAgAAQESAAMAAAABAAYAAAA'
  + 'AAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBA'
  + 'QEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQE'
  + 'BAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAEAAgMBEQACEQEDEQH/x'
  + 'ABKAAEAAAAAAAAAAAAAAAAAAAALEAEAAAAAAAAAAAAAAAAAAAAAAQEAAAAAAAAAAAAAAAA'
  + 'AAAAAEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwA/8H//2Q==';
let isImageAutomaticRotation;
function detectImageAutomaticRotation() {
  return new Promise((resolve) => {
    if (isImageAutomaticRotation === undefined) {
      const img = new Image();

      img.onload = () => {
        // 如果图片变成 1x2，说明浏览器对图片进行了回正
        isImageAutomaticRotation = img.width === 1 && img.height === 2;

        resolve(isImageAutomaticRotation);
      };

      img.src = testAutoOrientationImageURL;
    } else {
      resolve(isImageAutomaticRotation);
    }
  });
}

function getOrientation(file) {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line func-names
      EXIF.getData(file, async function () {
        EXIF.getAllTags(this);
        const orientation = EXIF.getTag(this, 'Orientation');
        resolve(orientation);
      });
    } catch (error) {
      reject(error);
    }
  });
}

export default async function (file, type, cb, httpapi = httpApi.updateImage) {
  if (!file.type.startsWith('image/')) {
    Toast('请选择正确的图片格式');
    return;
  }
  try {
    Toast.loading();
    const reader = new FileReader();
    const image = new Image();

    reader.addEventListener('error', (e = {}) => {
      Toast.clear();
      const message = `${e.message || reader.error.message || '读取文件失败'}`;
      Dialog.alert({
        title: '说明',
        messageAlign: 'left',
        message,
        confirmButtonText: '我知道了',
        confirmButtonColor: 'rgba(0,0,0,0.6)',
      });
    });

    reader.addEventListener('load', async (e) => {
      const base64 = e.target.result;
      image.src = base64;
      image.onload = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        // 图片原始尺寸
        const originWidth = image.width;
        const originHeight = image.height;

        // 最大尺寸限制
        const maxWidth = 1200;
        const maxHeight = 1200;
        // 目标尺寸
        let targetWidth = originWidth;
        let targetHeight = originHeight;
        // 图片尺寸超过1200x1200的限制
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > maxWidth / maxHeight) {
            // 更宽，按照宽度限定尺寸
            targetWidth = maxWidth;
            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
          } else {
            targetHeight = maxHeight;
            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
          }
        }
        // canvas对图片进行缩放
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // 清除画布
        ctx.clearRect(0, 0, targetWidth, targetHeight);
        // 判断图片是否需要旋转
        const rotation = await detectImageAutomaticRotation();
        if (!rotation) {
          const orientation = await getOrientation(file);
          switch (orientation) {
            case 3:
              ctx.rotate((180 * Math.PI) / 180);
              ctx.drawImage(image, -targetWidth, -targetHeight, targetWidth, targetHeight);
              break;
            case 6:
              ctx.rotate((90 * Math.PI) / 180);
              ctx.drawImage(image, 0, -targetWidth, targetHeight, targetWidth);
              break;
            case 8:
              ctx.rotate((270 * Math.PI) / 180);
              ctx.drawImage(image, -targetWidth, 0, targetHeight, targetWidth);
              break;
            default:
              ctx.drawImage(image, 0, 0, targetWidth, targetHeight);
          }
        }
        ctx.drawImage(image, 0, 0, targetWidth, targetHeight);
        // 图片压缩
        const photo = canvas.toDataURL(file.type);
        const resu = await httpapi({ type, photo });
        Toast.clear();
        if (resu) {
          Toast('上传成功');
          cb(photo);
        }
      };
    }, false);

    try {
      if (file) {
        reader.readAsDataURL(file);
      }
    } catch (err) {
      Toast.clear();
      // eslint-disable-next-line
      console.log(err);
    }
  } catch (error) {
    Toast.clear();
    // eslint-disable-next-line
    console.log(error);
  }
}
